<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import accordion from "@/components/ui/baseComponents/accordion.vue";
import salaryNotice from "../interviewOffers/SalaryAndNotice.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const interviewData = ref(null);
const groupedQuestions = ref(null);
const groupBy = (arr, key) => {
  const initialValue = {};
  return arr?.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};

const reRoute = () => {
  router.back();
};

onMounted(async () => {
  const { interview: data } = await store.dispatch(
    "InterviewProcess/fetchSingleInterview",
    {
      interview: route.params?.interviewId || 0,
    }
  );
  let groupedQuestion = data["interview_policies"].length
    ? groupBy(data["interview_policies"][0]?.questions, "head")
    : {};
  groupedQuestion["5"] = data["adhoc_questions"];
  groupedQuestion["6"] = data["candidate_questions"];
  //   groupedQuestion = [...groupedQuestion, ...data["candidate_questions"]];
  groupedQuestions.value = groupedQuestion;
  interviewData.value = data;
  if (data.application_status === "second-interview") {
    headsArray.value = secondHeadsarray.value;
    groupedQuestion = [];
    let groupedQuestion = data["interview_policies"].length
      ? groupBy(data["interview_policies"][0]?.questions, "head")
      : {};
    groupedQuestion["5"] = data["adhoc_questions"];
    groupedQuestion["6"] = data["candidate_questions"];
    groupedQuestions.value = groupedQuestion;
    console.log("coming here...", headsArray);
  }
});
var secondHeadsarray = ref([
  "",
  "",
  "",
  "",
  "AdHoc Questions",
  "Candidate's Questions",
]);
var headsArray = ref([
  "About the Candidate",
  "Role Specific Questions",
  "Notice description",
  "Questions from the Candidate",
  "AdHoc Questions",
  "Candidate's Questions",
]);
</script>

<template>
  <div class="w-11/12 mx-auto my-5">
    <div
      class="flex items-center text-teal mt-3 mb-2 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div v-if="interviewData">
      <div class="flex justify-between">
        <div class="">
          <div class="flex items-center">
            <h1 class="text-grey mr-2">
              {{ interviewData.hiring_request.job_title }}
            </h1>
            <p class="text-sm">({{ interviewData.practice.practice_name }})</p>
          </div>
          <p class="text-sm">
            {{ interviewData.hiring_request.reason_for_recruitment }}
          </p>

          <p class="text-sm my-5">Location: {{ interviewData.location }}</p>
        </div>
        <div class="">
          <p>
            Interviewee:
            <span>{{
              interviewData.user.profile.first_name +
              " " +
              interviewData.user.profile.last_name
            }}</span>
          </p>
          <p>
            Interviewer:
            <span>interviewer Name here</span>
          </p>
          <p>
            Interview Type:
            <span>{{
              interviewData.interview_type === "digital-interview"
                ? "digital"
                : "on-site"
            }}</span>
          </p>
          <p>
            Interview Progress:
            <span>{{
              interviewData.application_status === "first-interview"
                ? "first interview"
                : interviewData.application_status
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-y-2 my-5" v-if="groupedQuestions">
      <template v-for="item in Object.keys(groupedQuestions)" :key="item.index">
        <accordion
          :questionsData="groupedQuestions[item]"
          :title="headsArray[item - 1]"
          v-if="headsArray[item - 1] != ''"
        >
          <div class="w-auto break-all">
            <accordion
              v-for="(question, index) in groupedQuestions[item]"
              :key="index"
              :isInner="true"
              :questionsData="question"
              :title="'Q: ' + question.question"
            >
              <div
                v-if="question.answer"
                class="bg-lightGrey px-5 py-2 rounded-md"
              >
                <p class="text-gray-500"><b>Ans:</b> {{ question?.answer }}</p>
              </div>
              <div
                v-if="question.interview_answers"
                class="bg-lightGrey px-5 py-2 rounded-md"
              >
                <p class="text-gray-500">
                  <b>Ans:</b> {{ question?.interview_answers[0]?.answer }}
                </p>
              </div>
            </accordion>
          </div>
        </accordion>
      </template>
      <accordion :title="'Salary & Notice'" :showQuestionsCount="false">
        <div class="flex flex-col gap-y-3">
          <salaryNotice :salaryNotice="interviewData.interview_misc_info" />
        </div>
      </accordion>
      <accordion :title="'Conclusion'" :showQuestionsCount="false">
        <div class="flex flex-col gap-y-3">
          <accordion :title="'Score: '" :showQuestionsCount="false">
            <div class="bg-lightGrey px-5 py-2 rounded-md">
              <p class="text-gray-500">
                {{ interviewData?.interview_score?.total }}/80
              </p>
            </div>
          </accordion>
          <accordion :title="'Remarks: '" :showQuestionsCount="false">
            <div class="bg-lightGrey px-5 py-2 rounded-md">
              <p class="text-gray-500">
                {{
                  interviewData?.interview_score?.remarks
                    ? interviewData.interview_score.remarks
                    : "Unavailable"
                }}
              </p>
            </div>
          </accordion>
        </div>
      </accordion>
    </div>
  </div>
</template>

<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
