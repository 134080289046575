<script setup>
import { computed, defineEmits, defineProps } from "vue";
const props = defineProps({
  availability: {
    type: [Array, Object],
    default: () => [],
  },
});
const emits = defineEmits(["update:availability"]);
const days = ["M", "T", "W", "Th", "F", "Sa", "Su"];
const selectedDays = computed({
  get() {
    return props.availability;
  },
  set(value) {
    console.log(value)
    emits("update:availability", value);
  },
});
const setDays = (item) => {
  selectedDays.value.includes(item)
    ? (selectedDays.value = selectedDays.value.filter((iter) => iter != item))
    : (selectedDays.value = [...selectedDays.value, item]);
};
</script>
<template>
  <div class="flex justify-around border border-gray-500 flex-grow rounded-md">
    <div
      class="border border-gray-400 py-1 px-3 rounded-md my-1 cursor-pointer"
      :class="{ 'bg-teal text-white font-medium': selectedDays.includes(item) }"
      @click="setDays(item)"
      v-for="item in days"
      :key="item"
    >
      {{ item }}
    </div>
  </div>
</template>
