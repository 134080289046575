<script setup>
import { computed, onBeforeUnmount, defineProps, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import WeekSelector from "./weekSelector.vue";
const store = useStore();
const route = useRoute();
var propCheck = false;
const miscellaneousInformation = computed({
  get() {
    return store.getters["InterviewProcess/getMiscInformation"];
  },
  set(value) {
    store.commit("InterviewProcess/setMiscInformations", value);
  },
});
var salarydifference = computed(() => {
  return salaryInformation.value.expected_salary &&
    salaryInformation.value.current_salary
    ? salaryInformation.value.expected_salary -
        salaryInformation.value.current_salary
    : 0;
});
var salaryInformation = ref([]);
const percentagedifference = computed(() => {
  return salaryInformation.value.current_salary && salarydifference.value
    ? (
        (100 * salarydifference.value) /
        salaryInformation.value.current_salary
      ).toFixed(2)
    : 0;
});
const allTImes = ["AM", "PM", "both"];
const jobTypes = ["Full Time", "Part Time"];
const DBS = ["held", "not held"];
const Dismissals = [
  { title: "yes", value: 1 },
  { title: "no", value: 0 },
];
const noticeStarted = [
  { title: "yes", value: 1 },
  { title: "no", value: 0 },
];
const otherInterviews = [
  { title: "yes", value: 1 },
  { title: "no", value: 0 },
];
const saveMiscInfo = (value) => {
  //To-do : object formation needs to be refined for notice period and salaryInformation
  miscellaneousInformation.value = value;
  propCheck = true;
  console.log(miscellaneousInformation.value, "value at save");
};
// storing data while unmounting the component
onBeforeUnmount(async () => {
  if (propCheck == true) {
    const response = await store.dispatch("InterviewProcess/postMiscInfo", {
      interview: route.params.interviewId,
      ...miscellaneousInformation.value.salaryInformation,
      difference: salarydifference.value,
    });
    if (response.success) {
      console.log("go ahead and save the answer to the database");
    }
  }
  console.log(props.salaryNotice);
});

onMounted(() => {
  if (props.salaryNotice != null) {
    salaryInformation.value = props.salaryNotice;
  }
  if (props.salaryNotice === null) {
    console.log("null working");
  }
});

const props = defineProps({
  salaryNotice: { type: Object, required: false },
});
</script>
<template>
  <div class="flex flex-grow gap-5">
    <div class="bg-gray-200 flex flex-col flex-grow p-3 rounded-xl w-full">
      <p class="text-2xl font-medium">Salary Information</p>
      <div class="h-full w-full">
        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">Current Salary:</p>
          <input
            v-model="salaryInformation.current_salary"
            type="text"
            name="currentSalary"
            placeholder="£2000"
            class="
              form-input
              border border-gray-900
              p-2
              rounded-md
              bg-white
              placeholder-gray-400
              text-gray-500
              appearance-none
              w-full
              block
              focus:outline-none
            "
          />
        </div>
        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">Expected Salary:</p>
          <input
            v-model="salaryInformation.expected_salary"
            type="text"
            name="currentSalary"
            placeholder="£3000"
            class="
              form-input
              border border-gray-900
              p-2
              rounded-md
              bg-white
              placeholder-gray-400
              text-gray-500
              appearance-none
              w-full
              block
              focus:outline-none
            "
          />
        </div>
        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">Difference:</p>
          <input
            v-model="salarydifference"
            type="text"
            name="currentSalary"
            disabled
            placeholder="$1000"
            class="
              form-input
              border
              mr-5
              flex-grow
              border-gray-900
              p-2
              rounded-md
              bg-gray-200
              placeholder-gray-400
              text-gray-500
              appearance-none
              block
              focus:outline-none
            "
          />
          <p class="flex-none pr-5">%:</p>
          <input
            v-model="percentagedifference"
            type="text"
            disabled
            name="currentSalary"
            placeholder="18"
            class="
              form-input
              border border-gray-900
              p-2
              rounded-md
              bg-gray-200
              placeholder-gray-400
              text-gray-500
              appearance-none
              block
              focus:outline-none
            "
          />
        </div>

        <div class="flex items-center mb-2">
          <p class="pr-5">Availability / Flexibility</p>
          <WeekSelector v-model:availability="salaryInformation.availability" />
        </div>
        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">Available Times:</p>
          <div class="form-check mr-3" v-for="item in allTImes" :key="item">
            <input
              v-model="salaryInformation.available_time"
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeat bg-center bg-contain
                float-left
                mr-1
                cursor-pointer
              "
              type="radio"
              name="time"
              :value="item"
              :id="item"
            />
            <label class="capitalize inline-block text-gray-800" :for="item">
              {{ item }}
            </label>
          </div>
        </div>
        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">Job Type:</p>
          <div class="form-check mr-3" v-for="item in jobTypes" :key="item">
            <input
              v-model="salaryInformation.job_type"
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeat bg-center bg-contain
                float-left
                mr-1
                cursor-pointer
              "
              type="radio"
              name="jobType"
              :value="item"
              :id="item"
            />
            <label class="capitalize inline-block text-gray-800" :for="item">
              {{ item }}
            </label>
          </div>
        </div>
        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">DBS:</p>
          <div class="form-check mr-3" v-for="item in DBS" :key="item">
            <input
              v-model="salaryInformation.dbs"
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeat bg-center bg-contain
                float-left
                mr-1
                cursor-pointer
              "
              type="radio"
              name="dbs"
              :id="item"
              :value="item"
            />
            <label class="capitalize inline-block text-gray-800" :for="item">
              {{ item }}
            </label>
          </div>
        </div>

        <div class="flex items-center mb-2">
          <p class="flex-none pr-5">Dismissals / Disciplinaries:</p>
          <div class="form-check mr-3" v-for="item in Dismissals" :key="item">
            <input
              v-model="salaryInformation.dismissals"
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeatd bg-center bg-contain
                float-left
                mr-1
                cursor-pointer
              "
              type="radio"
              name="dismissals"
              :id="`dismissals${item.title}`"
              :value="item.value"
            />
            <label
              class="capitalize inline-block text-gray-800"
              :for="`dismissals${item.title}`"
            >
              {{ item.title }}
            </label>
          </div>
        </div>
        <span class="border border-gray-400 px-4 border-b-0 rounded"
          >Notes</span
        >
        <textarea
          v-model="salaryInformation.salary_notes"
          name="notes"
          id="notes"
          rows="5"
          class="border p-3 border-gray-400 w-full rounded-md"
        ></textarea>
      </div>
    </div>
    <div class="bg-gray-200 flex flex-col flex-grow p-3 rounded-xl w-full">
      <p class="text-2xl font-medium">Notice Period</p>
      <div class="flex items-center mb-2">
        <p class="flex-none pr-5">Given Notice?</p>
        <div
          class="form-check mr-3"
          v-for="item in noticeStarted"
          :key="`notice${item}`"
        >
          <input
            v-model="salaryInformation.given_notice"
            class="
              form-check-input
              appearance-none
              rounded-full
              h-4
              w-4
              border border-gray-300
              bg-white
              checked:bg-blue-600 checked:border-blue-600
              focus:outline-none
              transition
              duration-200
              mt-1
              align-top
              bg-no-repeat bg-center bg-contain
              float-left
              mr-1
              cursor-pointer
            "
            type="radio"
            name="notice"
            :id="`notice${item.title}`"
            :value="item.value"
          />
          <label
            class="capitalize inline-block text-gray-800"
            :for="`notice${item.title}`"
          >
            {{ item.title }}
          </label>
        </div>
      </div>
      <div class="flex items-center mb-2">
        <p class="flex-none pr-5">Notice Period Begun:</p>
        <input
          v-model="salaryInformation.notice_start"
          type="date"
          class="
            form-input
            border border-gray-900
            p-2
            rounded-md
            bg-white
            placeholder-gray-400
            text-gray-500
            appearance-none
            w-full
            block
            focus:outline-none
          "
        />
      </div>
      <div class="flex items-center mb-2">
        <p class="flex-none pr-5">Notice Period Length:</p>
        <input
          v-model="salaryInformation.notice_duration"
          type="text"
          placeholder="31 days"
          class="
            form-input
            border border-gray-900
            p-2
            rounded-md
            bg-white
            placeholder-gray-400
            text-gray-500
            appearance-none
            w-full
            block
            focus:outline-none
          "
        />
      </div>
      <div class="flex items-center mb-2">
        <p class="flex-none pr-5">Interviewing Elsewhere?</p>
        <div
          class="form-check mr-3"
          v-for="item in otherInterviews"
          :key="item"
        >
          <input
            v-model="salaryInformation.interviewing_elsewhere"
            class="
              form-check-input
              appearance-none
              rounded-full
              h-4
              w-4
              border border-gray-300
              bg-white
              checked:bg-blue-600 checked:border-blue-600
              focus:outline-none
              transition
              duration-200
              mt-1
              align-top
              bg-no-repeat bg-center bg-contain
              float-left
              mr-1
              cursor-pointer
            "
            type="radio"
            name="otherInterviews"
            :id="item.title"
            :value="item.value"
          />
          <label
            class="capitalize inline-block text-gray-800"
            :for="item.title"
          >
            {{ item.title }}
          </label>
        </div>
      </div>
      <div class="">
        <span class="border border-gray-400 px-4 border-b-0 rounded"
          >Notes</span
        >
        <textarea
          v-model="salaryInformation.notice_notes"
          name="notes"
          id="notes"
          rows="5"
          class="border p-3 border-gray-400 w-full rounded-md"
        ></textarea>
      </div>
      <div class="flex justify-end mt-auto">
        <base-button
          @click="
            saveMiscInfo({
              name: 'salaryInformation',
              value: salaryInformation,
              validator: 'salaryValidator',
            })
          "
          class="bg-gray-200 rounded-md px-3 py-2 my-4 focus:bg-yellow-300"
          >Save</base-button
        >
      </div>
    </div>
  </div>
</template>
