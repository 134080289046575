<template>
  <div
    class="w-full bg-lightGrey text-grey transition-all ease-in-out hover:bg-darkGrey hover:text-white duration-300"
    :class="[{ rounded: isInner }, { 'rounded-xl': !isInner }, classes]"
  >
    <div class="cursor-pointer p-1" @click.prevent="active = !active">
      <transition>
        <div class="flex justify-between items-center mx-2">
          <div class="flex justify-center items-center">
            <span
              v-if="!isInner"
              class="material-icons text-teal text-3xl font-bold"
              v-show="!active"
            >
              expand_more
            </span>
            <span
              v-if="!isInner"
              class="material-icons text-teal text-3xl font-bold"
              v-show="active"
            >
              expand_less
            </span>
            <span v-if="!isInner" class="font-bold">{{ title }}</span>
          </div>
          <div v-if="isInner" class="font-normal w-full">
            <div class="flex flex-wrap justify-between items-center">
              <span>{{ title }}</span>
              <div class="flex items-center capitalize">
                <span> {{ questionsData?.type }}</span>
                <span
                  class="material-icons text-teal text-3xl font-bold"
                  v-show="!active"
                >
                  expand_more
                </span>
                <span
                  class="material-icons text-teal text-3xl font-bold"
                  v-show="active"
                >
                  expand_less
                </span>
              </div>
            </div>
          </div>
          <div v-if="!isInner && showQuestionsCount">
            <span :class="isInner ? 'font-normal' : 'font-bold'"
              >{{ `${checkData} ${accordionText}` }}
            </span>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <transition>
    <div class="py-1 pl-8 block" v-if="active">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    accordionText: {
      type: String,
      default: "Questions",
    },
    title: {
      type: String,
      required: true,
    },
    questionsData: {
      type: Object,
      default: () => {},
    },
    isInner: {
      type: Boolean,
      default: false,
    },
    showAccordionText: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: "",
    },
    showQuestionsCount: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      active: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    checkData() {
      if (!this.isInner) {
        return this.questionsData?.length;
      }
      return "";
    },
  },
};
</script>
<style scoped>
.v-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
/*  Active state for enter. Applied during the entire entering phase. */
.v-enter-active {
  transition: all 0.3s ease-out;
}
/* End State */
.v-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.v-leave-from {
  opacity: 1;
  transform: translateY(0);
}
/* Active state for leave. Applied during the entire leaving phase. */
.v-leave-active {
  transition: all 0.3s ease-in;
}
/* End State */
.v-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
